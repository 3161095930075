.ContactForm{
    width: 80%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  background-color: rgba(9, 14, 25, 0.83);
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 32px;
  border-radius: 12px;
  box-shadow: rgba(23, 92, 230, 0.1) 0px 4px 24px;
  margin-top: 20px;

  gap: 12px;
  }
